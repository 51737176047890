<template>
  <div class="icons">
    <ControlComponent tip="Controle de Camadas" icon="layers" :child="LayerSwitcher" type="widget" />
    <ControlComponent tip="Informações do Mapa" icon="post" :child="MapPointInfoComponent" type="widget" />
    <ControlComponent tip="StreetView" icon="streetview" :child="StreetViewComponent" type="widget" />
    <ControlComponent tip="Filtrar Topologia da Rede" icon="topology" :child="FilterTopologyComponent" type="widget" />
    <ControlComponent tip="Dashboard" icon="dashboard" :child="DashboardComponent" type="widget" />
    <ControlComponent tip="Swap" icon="swipe" :child="SwapLayerComponent" type="widget" />
    <ControlBarSeparatorComponent />
    <ControlComponent tip="Voltar para a posição inicial" icon="home" :child="GoToHomeComponent" type="button" />
    <ControlComponent tip="Aproximar" icon="plus" :child="ZoomInComponent" type="button" />
    <ControlComponent tip="Afastar" icon="minus" :child="ZoomOutComponent" type="button" />
  </div>
</template>

<script>
/* eslint-disable */
import { markRaw } from "vue";
import ControlComponent from "./ControlComponent.vue";
import ControlBarSeparatorComponent from "./ControlBarSeparatorComponent.vue";
import ZoomInComponent from "./map/ZoomInComponent.vue";
import ZoomOutComponent from "./map/ZoomOutComponent.vue";
import GoToHomeComponent from "./map/GoToHomeComponent.vue";
import LayerSwitcher from "./map/LayerSwitcherComponent.vue";
import StreetViewComponent from "./map/StreetViewComponent.vue";
import MapPointInfoComponent from "./map/MapPointInfoComponent.vue";
import DashboardComponent from "./DashBoardComponent.vue";
import SwapLayerComponent from "./map/SwapLayerComponent.vue";
import FilterTopologyComponent from "./FilterTopologyComponent.vue";

export default {
  name: "ControlBarComponent",
  components: {
    ControlComponent,
    ControlBarSeparatorComponent,
    ZoomInComponent,
    GoToHomeComponent,
    LayerSwitcher,
    StreetViewComponent,
    MapPointInfoComponent,
    DashboardComponent,
    SwapLayerComponent,
    FilterTopologyComponent,
  },
  data() {
    return {
      ZoomInComponent: markRaw(ZoomInComponent),
      ZoomOutComponent: markRaw(ZoomOutComponent),
      GoToHomeComponent: markRaw(GoToHomeComponent),
      LayerSwitcher: markRaw(LayerSwitcher),
      StreetViewComponent: markRaw(StreetViewComponent),
      MapPointInfoComponent: markRaw(MapPointInfoComponent),
      DashboardComponent: markRaw(DashboardComponent),
      SwapLayerComponent: markRaw(SwapLayerComponent),
      FilterTopologyComponent: markRaw(FilterTopologyComponent),
    };
  },
};
</script>

<style scoped>
.icons {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 1rem;
  height: 1rem;
  background-size: 1rem 1rem;
  padding: 1rem 0.2rem;
  margin: 1rem 0.2rem;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
}

.icon:hover {
  cursor: pointer;
  background-color: #29323b;
  border-radius: 1rem;
}

.icon img {
  width: 100%;
  height: 100%;
}
</style>
