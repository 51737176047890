<template>
  <WidgetComponent title="Controle de Camadas" :height="'100%'">
    <el-scrollbar height="85vh">
      <el-tree
        :data="layers"
        show-checkbox
        node-key="id"
        :props="defaultProps"
        :default-checked-keys="checkedLayers"
        default-expand-all
        @check="handleCheckChange"
      >
        <template #default="{ node, data }">
          <div v-bind:class="{ 'ls-title': !node.isLeaf }" class="container-ls">
            <div class="item-ls">
              <div class="item-title-ls">
                {{ node.label }}
              </div>
              <div v-if="node.isLeaf" class="item-menu-ls">
                <el-dropdown @command="selectLayer(data)">
                  <div style="width: 100%; height: 100%">...</div>
                  <template #dropdown>
                    <el-dropdown-menu trigger="click">
                      <el-dropdown-item>
                        <el-popover
                          placement="right"
                          :width="400"
                          trigger="click"
                        >
                          <template #reference> Transparência </template>
                          Transparência - {{ node.label }}
                          <el-slider
                            @change="setLayerOpacity"
                            v-model="curruentLayerTransparency"
                          />
                        </el-popover>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
              <div class="item-legend-ls">
                <img v-if="node.isLeaf && data.showLegend" :src="data.legend" />
              </div>
            </div>
          </div>
        </template>
      </el-tree>
    </el-scrollbar>
  </WidgetComponent>
</template>

<script>
import WidgetComponent from "../WidgetComponent.vue";
import {
  handleVisibleLayers,
  getLayerOpacity,
  setLayerOpacity,
  createWmsLegendUrl
} from "../../helpers/layers.js";

export default {
  name: "LayerSwitcherComponent",
  components: {
    WidgetComponent,
  },
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "label",
        class: "legend",
      },
      layers: [],
      checkedLayers: [],
      curruentLayerTransparency: 50,
      curruentLayerId: null,
    };
  },
  mounted() {
    this.fillData();
    this.$emit("setWidth", "2px");
    this.emitter.on("add-layer", this.fillData);
  },
  methods: {
    selectLayer(layer) {
      this.curruentLayerId = layer.id;
      this.curruentLayerTransparency =
        getLayerOpacity(this.app.config.groups, layer.id) * 100;
    },
    setLayerOpacity(value) {
      setLayerOpacity(
        this.app.config.groups,
        this.curruentLayerId,
        value / 100
      );
    },
    fillData() {
      let data = [];
      let checked = [];
      const groups = this.app.config.groups;
      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];
        let groupData = {
          id: group.id,
          label: group.title,
          children: [],
        };
        for (let j = 0; j < group.layers.length; j++) {
          const layer = group.layers[j];
          groupData.children.push({
            id: layer.id,
            label: layer.title,
            legend: createWmsLegendUrl(layer),
            showLegend: layer.showLegend,
          });
          if (layer.visible) checked.push(layer.id);
        }
        data.push(groupData);
      }
      this.layers = data;
      this.checkedLayers = checked;
    },
    handleCheckChange(data, e) {
      handleVisibleLayers(
        this.app.config.groups,
        [...e.checkedKeys],
        this.app.map,
        data
      );
    },
  },
};
</script>

<style>
.item-ls {
  position: relative;
}
.item-ls > * {
  color: rgb(32, 32, 32);
}
.item-title-ls {
  width: 90%;
  float: left;
}
.item-menu-ls {
  width: 10%;
  float: right;
  text-align: right;
  font-size: 14px !important;
  letter-spacing: normal !important;
}
.item-legend-ls {
  width: 100%;
  float: left;
}
.el-tree,
.el-tree > * {
  background: none;
}
.el-tree-node__content {
  height: 100%;
  align-items: unset;
  padding: 0;
  margin: 0;
}
.container-ls {
  width: 100%;
  padding: 5px 0;
}
.container-ls div {
  font-size: 12px;
}
.container-ls img {
  padding-top: 0;
  padding-bottom: 0;
}
.el-icon {
  margin: 0 0;
}
.el-checkbox {
  height: 24px;
}
.ls-title {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
}
</style>
