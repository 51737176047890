import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { Image as ImageLayer } from "ol/layer";
import ImageWMS from "ol/source/ImageWMS";
import { v4 as uuidv4 } from "uuid";
import { Heatmap as HeatmapLayer } from "ol/layer";
import GeoJSON from "ol/format/GeoJSON";
import { Vector as VectorSource } from "ol/source";

function customImageLoadFunction(img, src, auth) {
  var oReq = new XMLHttpRequest();
  oReq.open("GET", src, true);
  oReq.responseType = "blob";

  if (auth) {
    oReq.setRequestHeader("Authorization", auth);
  }
  // eslint-disable-next-line
  emitter.emit("loading-started");
  oReq.onload = function () {
    var urlCreator = window.URL || window.webkitURL;
    var blob = oReq.response;
    var imageUrl = urlCreator.createObjectURL(blob);

    if (img.getImage) {
      img.getImage().src = imageUrl;
    } else {
      img.src = imageUrl;
    }

    // eslint-disable-next-line
    emitter.emit("loading-finished");
  };

  oReq.send();
}

function createWmsServiceUrl(layer) {
  let { service, layers, styles, format, transparent, version, srs } = layer;
  styles = styles || "";
  format = format || "image/png";
  transparent = transparent || "true";
  version = version || "1.1.1";
  srs = srs || "EPSG:4326";

  let url = `${service}?service=WMS&version=${version}&request=GetMap&layers=${layers}&styles=${styles}&format=${format}&transparent=${transparent}&srs=${srs}`;
  return url;
}

function createWmsLegendUrl(layer) {
  let { service, layers } = layer;
  let url = `${service}?service=WMS&request=GetLegendGraphic&format=image/png&layer=${layers}`;
  return url;
}

function createWfsServiceUrl(layer) {
  let { service, layers, version, srs } = layer;
  version = version || "1.0.0";
  srs = srs || "EPSG:4326";
  let url = `${service}?service=WFS&version=${version}&request=GetFeature&typeName=${layers}&outputFormat=application%2Fjson&srsName=${srs}`;
  return url;
}

function createWmsLayer(layer) {
  if (layer.ol) return;

  layer.ol = new ImageLayer({
    source: new ImageWMS({
      url: createWmsServiceUrl(layer),
      ratio: 1,
      serverType: "geoserver",
      imageLoadFunction: (img, src) => {
        customImageLoadFunction(img, src, null);
      },
    }),
    minResolution: layer.minResolution || 0,
    maxResolution: layer.maxResolution || 200000,
  });
  layer.ol.set("config", layer);

  if (layer.heatmap) {
    createHeatMapLayer(layer.heatmap);
  }
}

function createXyzLayer(layer) {
  const { service } = layer;

  if (layer.ol) return;

  layer.ol = new TileLayer({
    source: new XYZ({
      url: service,
      imageLoadFunction: (img, src) => {
        customImageLoadFunction(img, src, null);
      },
    }),
    minResolution: layer.minResolution || 0,
    maxResolution: layer.maxResolution || 200000,
  });
  layer.ol.set("config", layer);
}

function createHeatMapLayer(layer) {
  const { blur, radius, minResolution, maxResolution, gradient } = layer;

  if (layer.ol) return;

  layer.ol = new HeatmapLayer({
    source: new VectorSource({
      url: createWfsServiceUrl(layer),
      format: new GeoJSON(),
    }),
    blur,
    radius,
    gradient: gradient || ["#00f", "#0ff", "#0f0", "#ff0", "#f00"],
    minResolution: minResolution || 0,
    maxResolution: maxResolution || 200000,
  });

  layer.ol.on("change", (event) => {
    const zoom = event.target.getMapInternal().getView().getZoom();

    if (zoom > 15) {
      event.target.setRadius(zoom * 6);
      event.target.setBlur(zoom * 12);
    } else if (zoom > 14) {
      event.target.setRadius(zoom * 5);
      event.target.setBlur(zoom * 10);
    } else if (zoom > 13) {
      event.target.setRadius(zoom * 4);
      event.target.setBlur(zoom * 8);
    } else if (zoom > 12) {
      event.target.setRadius(zoom * 3);
      event.target.setBlur(zoom * 6);
    } else {
      event.target.setRadius(radius);
      event.target.setBlur(blur);
    }
  });
}

function createLayers(groups) {
  groups = setLayersIds(groups);
  let visibleLayers = [];

  for (let i = 0; i < groups.length; i++) {
    const group = groups[i];

    for (let j = 0; j < group.layers.length; j++) {
      let layer = group.layers[j];

      switch (layer.type) {
        case "wms":
          createWmsLayer(layer);
          break;
        case "xyz":
          createXyzLayer(layer);
          break;
        case "heatmap":
          createHeatMapLayer(layer);
          break;
        default:
          break;
      }

      if (layer.visible) visibleLayers.push(layer.ol);
      if (layer.visible && layer.heatmap) visibleLayers.push(layer.heatmap.ol);
    }
  }

  setZIndex(groups);

  return visibleLayers;
}

function setLayersIds(groups) {
  for (let i = 0; i < groups.length; i++) {
    let group = groups[i];
    group.id = uuidv4();
    for (let j = 0; j < group.layers.length; j++) {
      let layer = group.layers[j];
      if (layer.id) continue;
      layer.id = uuidv4();
    }
  }

  return groups;
}

function handleVisibleLayers(groups, visibleLayers, map) {
  let layers = map.getLayers().getArray();

  for (let i = 0; i < groups.length; i++) {
    let group = groups[i];

    for (let j = 0; j < group.layers.length; j++) {
      let layer = group.layers[j];
      let isLayerOnMap = layers.includes(layer.ol);
      layer.visible = visibleLayers.includes(layer.id);

      if (layer.visible && !isLayerOnMap) map.addLayer(layer.ol);
      else if (!layer.visible && isLayerOnMap) map.removeLayer(layer.ol);
    }
  }
}

function setZIndex(groups) {
  let index = 1000;
  for (let i = groups.length - 1; i >= 0; i--) {
    const group = groups[i];
    for (let j = group.layers.length - 1; j >= 0; j--) {
      let layer = group.layers[j];
      layer.ol.setZIndex(index++);
      if (layer.heatmap) {
        layer.heatmap.ol.setZIndex(index++);
      }
    }
  }
}

function setLayerOpacity(groups, id, opacity) {
  for (let i = 0; i < groups.length; i++) {
    let group = groups[i];

    for (let j = 0; j < group.layers.length; j++) {
      let layer = group.layers[j];

      if (layer.id === id) {
        layer.opacity = opacity;
        layer.ol.setOpacity(opacity);
      }
    }
  }
}

function getLayerOpacity(groups, id) {
  for (let i = 0; i < groups.length; i++) {
    let group = groups[i];

    for (let j = 0; j < group.layers.length; j++) {
      let layer = group.layers[j];

      if (layer.id === id) return layer.ol.getOpacity();
    }
  }
}

function updateWmsParamsLayers(groups, params) {
  for (let i = 0; i < groups.length; i++) {
    let group = groups[i];

    for (let j = 0; j < group.layers.length; j++) {
      let layer = group.layers[j];
      if (layer.ol.getSource() instanceof ImageWMS) {
        layer.ol.getSource().updateParams(params);
      }
    }
  }
}

export {
  createLayers,
  handleVisibleLayers,
  setLayerOpacity,
  getLayerOpacity,
  updateWmsParamsLayers,
  createWmsLegendUrl,
  customImageLoadFunction,
};
