<template>
  <!-- <WidgetComponent title="Informações do Poste" width="500px">
    <el-result :title="msg"> </el-result>
  </WidgetComponent> -->
  <el-popover
    :virtual-ref="iconRef"
    trigger="click"
    placement="right"
    virtual-triggering
  >
    <span> {{ msg }} </span>
  </el-popover>
</template>

<script>
//import WidgetComponent from "../WidgetComponent.vue";
import { getInfoByCooordinates } from "../../services/agiltec.js";
import ImageWMS from "ol/source/ImageWMS";
import { transform } from "ol/proj";
import { ElNotification } from "element-plus";

export default {
  name: "PointInfoComponent",
  components: {
    //WidgetComponent,
  },
  props: ["componentId", "iconId"],
  data() {
    return {
      postId: null,
      showPostData: false,
      msg: "Clique sobre o mapa para obter informações.",
      iconRef: this.$parent.$refs[this.iconId],
    };
  },
  mounted() {
    const layers = this.findLayersConfig();
    if (layers.length == 0) {
      this.msg = "";
      ElNotification({
        title: "Atenção",
        message: "Habilite ao menos uma camada para obter informações.",
        type: "warning",
      });
      this.close();
      return;
    }

    const mapContainer = document.getElementById("map");
    mapContainer.style.cursor = "crosshair";
    this.registerEvents();
  },
  methods: {
    findLayersConfig() {
      let configs = [];
      const layers = this.app.map.getLayers().getArray();
      for (let i = 0; i < layers.length; i++) {
        const layer = layers[i];
        let source = layer.getSource();
        if (source instanceof ImageWMS && layer.get("config")) {
          configs.push(layer.get("config"));
        }
      }
      return configs;
    },
    mapClickEvent(evt) {
      const coord = transform(evt.coordinate, "EPSG:3857", "EPSG:4326");

      let promises = [];

      this.msg = "Buscando informações...";
      this.$root.$refs.infoComponent.clearData();
      this.$root.$refs.infoComponent.open();

      const layersConfig = this.findLayersConfig();
      for (let i = 0; i < layersConfig.length; i++) {
        const layer = layersConfig[i];
        const params = {
          coordinates: coord,
          layer: layer,
          urlService: layer.service,
        };
        promises.push(getInfoByCooordinates(params));
      }

      this.$root.$refs.infoComponent.startLoading();
      Promise.all(promises)
        .then((responses) => {
          for (let response of responses) {
            if (response.features.length > 0) {
              this.$root.$refs.infoComponent.addGroupInfo({
                title: response.layer.title,
                features: response.features,
              });
            }
          }
          this.$root.$refs.infoComponent.open();
        })
        .then(() => {
          this.$root.$refs.infoComponent.stopLoading();
        })
        .catch((error) => {
          this.$root.$refs.infoComponent.stopLoading();
          ElNotification({
            title: "Erro",
            message: "Contacte o administrador do sistema.",
            type: "error",
          });
          console.error(error);
        });

      this.close();
    },
    close() {
      const mapContainer = document.getElementById("map");
      mapContainer.style.cursor = "unset";
      this.app.map.un("click", this.mapClickEvent);
      this.emitter.off("close-widget", this.onCloseEvt);
      this.$parent.close();
    },
    onCloseEvt(componentId) {
      if (componentId == this.componentId) {
        this.close();
      }
    },
    registerEvents() {
      this.emitter.on("close-widget", this.onCloseEvt);
      this.app.map.on("click", this.mapClickEvent);
    },
  },
};
</script>

<style scoped></style>
