<template>
  <div class="box">
    <div class="row container">
      <div class="control-bar">
        <ControlBarComponent />
      </div>
      <div class="content">
        <GeoCodingComponent />
        <MapComponent />
        <FooterComponent />
      </div>
    </div>
    <!--  -->
  </div>
  <InfoComponent ref="infoComponent" />
  <LoginComponent />
</template>

<script>
/* eslint-disable */
import MapComponent from "./components/MapComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";
import ControlBarComponent from "./components/ControlBarComponent.vue";
import GeoCodingComponent from "./components/map/GeoCodingComponent.vue";
import InfoComponent from "./components/InfoComponent.vue";
import LoginComponent from "./components/LoginComponent.vue";
import FilterTopologyComponent from "./components/FilterTopologyComponent.vue";

export default {
  name: "App",
  components: {
    MapComponent,
    FooterComponent,
    ControlBarComponent,
    GeoCodingComponent,
    InfoComponent,
    LoginComponent,
    FilterTopologyComponent,
  },
};
</script>

<style>
* {
  font-family: "Roboto", sans-serif !important;
}

html,
body,
#app {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
}

.box .row.container {
  flex: 1 1 auto;
  display: flex;
  flex-flow: row;
}

.box .row.footer {
  flex: 0 1 8px;
}

.content {
  flex: 1 1 auto;
  border-radius: 8px;
}

.control-bar {
  flex: 0 0 3rem;
  background: #1d2731;
}

.info {
  flex: 0 0 600px;
  background: #ffffff;
  width: 600px;
}
</style>
