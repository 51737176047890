import config from "../../app.config.js";
import { transform } from "ol/proj";

const base_url = config.base_url;
const imageserver_url = config.imageserver_url;
const geoserver_url = config.geoserver_url;
const webgis_url = config.webgis_url;

async function getInfoByCooordinates({ coordinates, layer, urlService }) {
  const [x, y] = coordinates;
  const params = new URLSearchParams({
    service: "WFS",
    version: "1.0.0",
    request: "GetFeature",
    typeName: layer.layers,
    maxFeatures: "1",
    outputFormat: "application/json",
    srsName: "EPSG:4326",
    cql_filter: `DWITHIN(geom, SRID=4326;POINT(${x} ${y}),3,meters)`,
  });

  urlService = urlService.split("/wms")?.[0] || urlService;

  let response = await fetch(urlService + "/ows?" + params);
  let json = await response.json();
  return { ...json, layer: layer };
}

//--------------------------- Validar as funcionalidades abaixo ---------------------------//

async function listPostes(id) {
  const url = base_url + "Poste/GetById/" + id;
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function listImagesById(id) {
  const url = base_url + "Foto/ListById/" + id;
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json().then((data) => {
    let id = 0;
    return data.source.map((feature) => {
      return {
        id: ++id,
        url: imageserver_url + feature.caminho.replace(/\\/g, "/"),
      };
    });
  });
}

async function getPostData(id) {
  const url = base_url + "Poste/GetById/" + id;
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getPostAnomalyData(id) {
  const url = base_url + "Poste/ListAnomaliasById/" + id;
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getPostShareData(id) {
  const url = base_url + "UsoMutuo/ListByPoste/" + id;
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getPostIPData(id) {
  const url = base_url + "ILuminacaoPublica/ListByPoste/" + id;
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getIpAnamaly(id) {
  const url = base_url + "ILuminacaoPublica/ListAnomaliasById/" + id;
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getPosteByCooordinates(coordinates) {
  const [x, y] = coordinates;
  const params = new URLSearchParams({
    service: "WFS",
    version: "1.0.0",
    request: "GetFeature",
    typeName: "agiltecwebgis:ponto_geografico",
    maxFeatures: "1",
    outputFormat: "application/json",
    cql_filter: `DWITHIN(geom,POINT(${x} ${y}),4,meters)`,
  });

  const response = await fetch(geoserver_url + "/ows?" + params);
  return response.json();
}

/* Dashboard */
async function getOcupanteUsoMutuo() {
  const url = base_url + "UsoMutuo/TipoOcupante";
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getMunicipiosList() {
  const url = base_url + "UsoMutuo/ListMunicipio";
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getTotalPontos() {
  const url = base_url + "UsoMutuo/TotalPontos";
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getTotalUsoCompartilhado() {
  const url = base_url + "UsoMutuo/TotalUsoCompartilhado";
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getTotalIp() {
  const url = base_url + "UsoMutuo/TotalIp";
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getTotalSemIp() {
  const url = base_url + "UsoMutuo/TotalPontosSemIPUsocompartilhado";
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getVaporSodio() {
  const url = base_url + "UsoMutuo/VaporSodio";
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getVaporMercurio() {
  const url = base_url + "UsoMutuo/VaporMercurio";
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getLed() {
  const url = base_url + "UsoMutuo/Led";
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getMultiVaporMercurio() {
  const url = base_url + "UsoMutuo/MultivaporMetalico";
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getTotalPontosMunicipio(municipio) {
  const url = base_url + "UsoMutuo/TotalPontosMunicipio/" + municipio;
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getTotalUsoCompartilhadoMunicipio(municipio) {
  const url = base_url + "UsoMutuo/TotalUsoCompartilhadoMunicipio/" + municipio;
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getTotalIpMunicipio(municipio) {
  const url = base_url + "UsoMutuo/TotalIpMunicipio/" + municipio;
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getTotalSemIpMunicipio(municipio) {
  const url =
    base_url +
    "UsoMutuo/TotalPontosSemIPUsocompartilhadoMunicipio/" +
    municipio;
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getTipoOcupanteMunicipio(municipio) {
  const url = base_url + "UsoMutuo/TipoOcupanteMunicipio/" + municipio;
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getVaporSodioMunicipio(municipio) {
  const url = base_url + "UsoMutuo/VaporSodioMunicipio/" + municipio;
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getVaporMercurioMunicipio(municipio) {
  const url = base_url + "UsoMutuo/VaporMercurioMunicipio/" + municipio;
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getLedMunicipio(municipio) {
  const url = base_url + "UsoMutuo/LedMunicipio/" + municipio;
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function getMultiVaporMercurioMunicipio(municipio) {
  const url = base_url + "UsoMutuo/MultivaporMetalicoMunicipio/" + municipio;
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

async function login(user, passwd) {
  const url = base_url + "Usuario/Logon";
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      userName: user,
      password: passwd,
      moduloId: 0,
      machine: "1",
      versionSystem: "1",
    }),
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "ZmVkYWY3ZDg4NjNiNDhlMTk3YjkyODdkNDkyYjcwOGVTdGFnaW5n",
    },
  });

  return response.json();
}

// Árvore
async function getArvoresByCooordinates(coordinates) {
  const [x, y] = transform(coordinates, "EPSG:3857", "EPSG:4326");
  const params = new URLSearchParams({
    service: "WFS",
    version: "1.0.0",
    request: "GetFeature",
    typeName: "agiltecwebgis:arvores",
    maxFeatures: "1",
    outputFormat: "application/json",
    cql_filter: `DWITHIN(geom,POINT(${x} ${y}),4,meters)`,
  });

  const response = await fetch(geoserver_url + "/ows?" + params);
  return response.json();
}

async function getImagensArvores(id) {
  const url = webgis_url + "arvores/" + id + "/imagens";
  const response = await fetch(url);
  return response.json();
}

async function getArvoreInfo(id) {
  const url = webgis_url + "arvores/" + id;
  const response = await fetch(url);
  return response.json();
}

export {
  getInfoByCooordinates,
  listPostes,
  listImagesById,
  getPostData,
  getPostAnomalyData,
  getPostShareData,
  getPostIPData,
  getIpAnamaly,
  getPosteByCooordinates,
  getOcupanteUsoMutuo,
  getMunicipiosList,
  getTotalPontos,
  getTotalUsoCompartilhado,
  getTotalIp,
  getTotalSemIp,
  getVaporSodio,
  getVaporMercurio,
  getLed,
  getMultiVaporMercurio,
  getTotalPontosMunicipio,
  getTotalUsoCompartilhadoMunicipio,
  getTotalIpMunicipio,
  getTotalSemIpMunicipio,
  getTipoOcupanteMunicipio,
  getVaporSodioMunicipio,
  getVaporMercurioMunicipio,
  getLedMunicipio,
  getMultiVaporMercurioMunicipio,
  login,
  getArvoresByCooordinates,
  getImagensArvores,
  getArvoreInfo,
};
