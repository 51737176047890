<template>
  <div class="container" :style="{ zIndex: zIndex }">
    <el-page-header @back="goBack">
      <template #content>
        <span class="title"> TOPOLOGIA DE ALIMENTADOR </span>
      </template>
    </el-page-header>

    <div class="content">
      <el-form :inline="false" :model="form" label-width="auto" style="max-width: 600px">
        <el-form-item label="Subestação">
          <el-select-v2 v-model="subestacao" :options="subestacoes" filterable placeholder="Selecione..."
            style="width: 250px">
          </el-select-v2>
        </el-form-item>

        <el-form-item label="Alimentador">
          <el-select-v2 v-model="alimentador" :options="alimentadores" filterable multiple clearable collapse-tags
            placeholder="Selecione..." style="width: 250px">
          </el-select-v2>
        </el-form-item>

        <el-form-item label="Cor">
          <el-color-picker v-model="color" />
        </el-form-item>
      </el-form>

      <el-button class="btn" type="primary" @click="addFilter">
        Adicionar
      </el-button>

      <el-table :data="filters" style="width: 100%; max-height: 400px">
        <el-table-column prop="subestacao" label="Subestação" />
        <el-table-column prop="alimentador_label" label="Alimentador" />
        <el-table-column fixed="right" label="" width="120">
          <template #default="scope">
            <el-button link type="primary" size="small">
              <el-color-picker v-model="scope.row.color" @change="buildLayers" />
            </el-button>

            <el-tooltip class="box-item" effect="dark" content="Remover filtro" placement="top">
              <el-button @click="removeFilter(scope)" link type="primary" size="small"><el-icon>
                  <Close />
                </el-icon></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <el-button @click="removeAllfilters" class="btn">Limpar</el-button>
    </div>
  </div>
</template>

<script>
import { Image as ImageLayer } from "ol/layer";
import ImageWMS from "ol/source/ImageWMS";
import { customImageLoadFunction } from "../helpers/layers.js";
import { ElNotification } from 'element-plus';

const defaultColors = [
  "#e41a1c",
  "#377eb8",
  "#4daf4a",
  "#984ea3",
  "#ff7f00",
  "#ffff33",
  "#a65628",
  "#f781bf",
];

export default {
  name: "InfoComponent",
  components: {},
  data: () => {
    return {
      show: false,
      isLoading: false,
      subestacao: "",
      subestacoes: [],
      alimentador: [],
      alimentadores: [],
      color: "#fff",
      filters: [],
      layer: null,
      form: {},
      zIndex: 1000,
    };
  },
  mounted() {
    this.layer = new ImageLayer({
      source: new ImageWMS({
        url: this.app.config.geoserver_url + "/wms",
        serverType: "geoserver",
        crossOrigin: "anonymous",
        imageLoadFunction: (img, src) => {
          customImageLoadFunction(img, src, null);
        },
      }),
      zIndex: 9999,
    });
    this.app.map.addLayer(this.layer);
    this.getOptions();
    this.color = this.getRandomColor();
    this.zIndex = this.app.widgetZIndex++;
  },
  unmounted() {
    this.app.map.removeLayer(this.layer);
  },
  methods: {
    buildLayers() {
      const name = `FiscalizacaoWeb:vw_trechos_primarios_alimentadores_subestacoes,FiscalizacaoWeb:vw_trechos_secundarios_alimentadores_subestacoes`;

      const names = [];
      const cqls = [];
      const colors = [];
      for (let i = 0; i < this.filters.length; i++) {
        names.push(name);
        cqls.push(
          `alimentador_id=${this.filters[i].alimentador};alimentador_id=${this.filters[i].alimentador}`
        );
        colors.push(
          `color${this.filters[i].alimentador}:${this.filters[i].color}`
        );
      }

      if (names.length == 0) {
        this.layer.getSource().updateParams({
          layers: "",
          cql_filter: "",
          env: "",
        });
        this.layer.setVisible(false);
        return;
      }

      this.layer.getSource().updateParams({
        layers: names.join(","),
        cql_filter: cqls.join(";"),
        env: colors.join(";"),
      });
      this.layer.setVisible(true);
    },
    removeAllfilters() {
      this.filters = [];
      this.color = this.getRandomColor();
      this.alimentador = [];
      this.subestacao = "";
      this.buildLayers();
    },
    removeFilter(scope) {
      this.filters = this.filters.filter((f) => f != scope.row);
      this.buildLayers();
    },
    addFilter() {
      for (let i = 0; i < this.alimentador.length; i++) {
        const al = this.alimentador[i];

        const subestacao = this.subestacoes.find(
          (se) => se.value == this.subestacao
        );

        const alimentador = subestacao.alimentadores.find((a) => a.value == al);

        this.filters.push({
          subestacao: this.subestacao,
          alimentador: al,
          alimentador_label: alimentador.label,
          color: this.color,
        });
      }
      this.color = this.getRandomColor();
      this.alimentador = [];
      this.subestacao = "";
      this.buildLayers();
    },
    getRandomColor() {
      return defaultColors[~~(Math.random() * defaultColors.length)];
    },
    getOptions() {
      const params = new URLSearchParams({
        service: "WFS",
        version: "1.0.0",
        request: "GetFeature",
        typeName: "FiscalizacaoWeb:vw_alimentadores_subestacoes",
        outputFormat: "application/json",
        srsName: "EPSG:4326",
      });
      const url = `${this.app.config.geoserver_url}/ows?${params.toString()}`;

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          //get unique subestacoes
          let subestacoes = {};
          for (let i = 0; i < data.features.length; i++) {
            let seFeature = data.features[i];
            let subestacao = seFeature.properties.nome_subestacao;

            if (!subestacoes[subestacao]) {
              let alimentadores = {};
              for (let j = 0; j < data.features.length; j++) {
                let alFeature = data.features[j];
                let alimentador = alFeature.properties.nome_alimentador;
                let alimentador_id = alFeature.properties.id;
                let alSubestacao = alFeature.properties.nome_subestacao;

                if (!alimentadores[alimentador] && alSubestacao == subestacao) {
                  alimentadores[alimentador] = {
                    value: alimentador_id,
                    label: alimentador,
                  };
                }
              }

              subestacoes[subestacao] = {
                value: subestacao,
                label: subestacao,
                geom: seFeature.geometry,
                alimentadores: Object.values(alimentadores),
              };
            }
          }
          this.subestacoes = Object.values(subestacoes);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    open() {
      this.show = true;
    },
    goBack() {
      this.show = false;
      this.$emit("close");
    },
    goToDashboard() {
      ElNotification({
        title: 'Error',
        message: 'Falha ao carregar dashboard!',
        type: 'error',
      })
    },
  },
  watch: {
    subestacao() {
      const subestacao = this.subestacoes.find(
        (se) => se.value == this.subestacao
      );
      this.alimentadores = subestacao?.alimentadores || [];
    },
  },
};
</script>

<style scoped>
.container {
  width: calc(600px);
  height: 100%;
  padding: 1.5rem 2rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  background: #fff;
}

.content {
  width: 100%;
  padding-top: 1.5rem;
  background: #fff;
  position: relative;
}

.title {
  font-size: 1rem;
  font-weight: bold;
}

.btn {
  margin-top: 1rem;
  margin-bottom: 1rem;
  float: right;
}

.btn-left {
  margin-top: 1rem;
  margin-bottom: 1rem;
  float: left;
}
</style>

<style>
/* .el-tabs--border-card > .el-tabs__content {
  height: calc(100vh - 175px);
} */
.el-descriptions__label.el-descriptions__cell.is-bordered-label {
  font-weight: 700;
  color: #0f0f0f;
  background: #fefefe;
}

.el-descriptions__body .el-descriptions__table.is-bordered .el-descriptions__cell {
  border: none;
  border-bottom: 1px dotted #d8dadf;
  padding: 11px;
}

.el-tabs {
  --el-tabs-header-height: 32px;
}

.el-tabs__item {
  font-size: 12px !important;
  padding: 0 8px !important;
}
</style>
