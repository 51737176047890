<template>
<div class="separator"></div>
</template>

<script>
export default {
  name: "ControlBarSeparatorComponent",
};
</script>

<style scoped>
.separator {
  width: 100%;
  height: 1rem;
  padding: 0.5rem;
  background: url("../assets/icons/separator.svg") no-repeat center center;
  background-size: 100% 1rem;
}
</style>
