<template>
  <div></div>
</template>

<script>
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { OverviewMap } from "ol/control";

export default {
  name: "OverViewComponent",
  mounted() {
    const overviewMapControl = new OverviewMap({
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
    });
    this.app.map.addControl(overviewMapControl);
  },
};
</script>
<style>
.ol-overviewmap {
  top: unset;
  left: unset;
  right: 4px;
  bottom: 4px;
  background: #1d2731 !important;
  overflow: hidden;
  z-index: 10;
}

.ol-control {
  background-color: rgb(255, 255, 255);
}

.ol-control button {
  z-index: 10;
  transform: rotate(180deg);
  color: white;
  height: 24px;
  width: 24px;
  font-size: 1.5rem;
  line-height: 0.4em;
  background-color: #1d2731 !important;
  border: none;
  /* border-radius: 16px; */
  cursor: pointer;
}
</style>