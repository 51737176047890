const GEOSERVER_URL = process.env.VUE_APP_GEOSERVER_URL;
const API_URL = process.env.VUE_APP_API_URL;

export default {
  api_url: API_URL,
  geoserver_url: GEOSERVER_URL,
  routes: [
    {
      title: "Árvores",
      path: "arvores",
      layers: [
        "Árvores",
        "OpenStreetMap",
        "ESRI Satélite",
        "Divisão Administrativa",
      ],
    },
  ],
  center: [-52.33, -31.29],
  //center: [-51.1932, -30.0156],
  zoom: 8,
  maxZoom: 19,
  groups: [
    {
      title: "Pontos Geográficos",
      layers: [
        {
          title: "Aéreo sem Estrutura (FLY-TAP)",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:aereo_sem_estrutura_fly-tap",
          type: "wms",
          visible: false,
          showLegend: true,
          tableName: "pontos_geograficos",
          maxResolution: 5,
        },
        {
          title: "Mufla",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:mufla",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
        {
          title: "Poste Circular",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:poste_circular",
          type: "wms",
          visible: true,
          showLegend: true,
          maxResolution: 5,
        },
        {
          title: "Caixa Subterrânea",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:caixa_subterranea",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
        {
          title: "Base Solo",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:base_solo",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
        {
          title: "Base Disjuntora",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:base_disjuntora",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
        {
          title: "Poste Duplo T",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:poste_duplo_t",
          type: "wms",
          visible: true,
          showLegend: true,
          maxResolution: 5,
        },
      ],
    },
    {
      title: "Instalções de Manobra",
      layers: [
        {
          title: "Base Fusível Aberta",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:base_fusivel_aberta",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
        {
          title: "Base Fusível Fechada",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:base_fusivel_fechada",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
        {
          title: "Chave Faca Aberta",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:chave_faca_aberta",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
        {
          title: "Chave Faca Fechada",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:chave_faca_fechada",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
        {
          title: "Chave Óleo Aberta",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:chave_oleo_aberta",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
        {
          title: "Chave Óleo Fechada",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:chave_oleo_fechada",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
        {
          title: "Disjuntor Aberta",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:disjuntor_aberta",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
        {
          title: "Disjuntor Fechada",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:disjuntor_fechada",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
        {
          title: "Religador Automático Aberto",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:religador_automatico_aberto",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
        {
          title: "Religador Automático Fechado",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:religador_automatico_fechado",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
        {
          title: "Seccionalizador Automático Aberto",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:seccionalizador_automatico_aberto",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
      ],
    },
    {
      title: "Instalação Carga",
      layers: [
        {
          title: "Banco Capacitor",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:banco_capacitor",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
        {
          title: "Estação Transformadora Particular",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:estacao_transformadora_particular",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
        {
          title: "Instalação Transformadora com Chave",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:instalacao_transformadora_com_chave",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
        {
          title: "Instalação Transformadora Companhia",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:instalacao_transformadora_companhia",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
      ],
    },
    {
      title: "Instalação Regulação",
      layers: [
        {
          title: "Instalação Regulação",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:instalacao_regulacao",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
      ],
    },
    {
      title: "Fiscalização",
      layers: [
        {
          title: "Postes",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:fiscalizacao",
          type: "wms",
          visible: false,
          showLegend: true,
          minResolution: 0,
          maxResolution: 5,
        },
      ],
    },
    {
      title: "Outros Pontos",
      layers: [
        {
          title: "Concentração de Pontos",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wfs`,
          layers: "FiscalizacaoWeb:heatmap",
          visible: true,
          type: "heatmap",
          radius: 12,
          blur: 40,
          minResolution: 4,
        },
        {
          title: "Fotos",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:vw_fotos",
          type: "wms",
          visible: false,
          showLegend: true,
          maxResolution: 5,
        },
        {
          title: "Pontos de Entrega",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:pontos_de_entrega",
          type: "wms",
          visible: false,
          showLegend: true,
          minResolution: 0,
          maxResolution: 4,
        },
        {
          title: "Subestações",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:subestacoes",
          type: "wms",
          visible: false,
          showLegend: true,
        },
      ],
    },
    {
      title: "Trechos de Rede",
      layers: [
        {
          title: "Trecho Primário",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:trechos_primarios",
          type: "wms",
          visible: true,
          showLegend: true,
          maxResolution: 10,
        },
        {
          title: "Trecho Secundário",
          service: `${GEOSERVER_URL}/FiscalizacaoWeb/wms`,
          layers: "FiscalizacaoWeb:trechos_secundarios",
          type: "wms",
          visible: true,
          showLegend: true,
          maxResolution: 10,
        },
      ],
    },
    {
      title: "Mapas Base",
      layers: [
        {
          title: "OpenStreetMap",
          service: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          type: "xyz",
          visible: false,
          //   minResolution: 0,
          //   maxResolution: 70,
        },
        {
          title: "ESRI Satélite",
          service:
            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
          type: "xyz",
          visible: false,
          //   minResolution: 70,
          //   maxResolution: 200000,
        },
        {
          title: "Google Satélite",
          service: "http://mt1.google.com/vt/lyrs=y&hl=pl&&x={x}&y={y}&z={z}",
          type: "xyz",
          visible: false,
        },
        {
          title: "Positron - Carto Light",
          service:
            "http://cartodb-basemaps-a.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
          type: "xyz",
          visible: true,
        },
      ],
    },
  ],
};
