<template>

  <Teleport to="body">
    <div v-if="show" class="container" :style="{ zIndex: zIndex }">
      <el-page-header @back="goBack">
        <template #content>
          <span class="title"> INFORMAÇÕES </span>
        </template>
      </el-page-header>
      <div v-loading="isLoading" element-loading-text="Buscando informações..." class="content">
        <el-empty v-if="!data.length" description="Nenhuma informação encontrada" />

        <el-tabs v-if="data.length" v-model="activeName" @tab-change="drawGeoms" type="card">
          <el-tab-pane v-for="item in data" v-bind:key="item" :label="item.title" :name="item.title">
            <el-descriptions size="small" direction="horizontal" :column="1" border>
              <el-descriptions-item v-for="(prop, key) in item.properties" v-bind:key="key" :label="key" width="50%"
                align="right" label-align="left">
                {{ prop }}
              </el-descriptions-item>
            </el-descriptions>
            <el-empty v-if="!item.images" description="Nenhuma foto encontrada" />
            <ImagesComponent v-if="item.images" :images="item.images" />
          </el-tab-pane>
        </el-tabs>

      </div>
    </div>
  </Teleport>
</template>

<script>
import { Vector as VectorLayer } from "ol/layer";
import VectorSource from "ol/source/Vector";
import GeoJSON from 'ol/format/GeoJSON.js';
import Feature from 'ol/Feature.js';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style.js';
import ImagesComponent from "./ImagesComponent.vue";


let layer = new VectorLayer({
  source: new VectorSource(),
  style: new Style({
    stroke: new Stroke({
      color: '#00ffff44',
      width: 10,
    }),
    fill: new Fill({
      color: '#00ffff44',
    }),
    image: new CircleStyle({
      radius: 10,
      fill: new Fill({
        color: '#00ffff44',
      }),
      stroke: new Stroke({
        color: '#00ffff44',
      }),
    }),
  }),
});
layer.setZIndex(99999);
export default {
  name: "InfoComponent",
  components: { ImagesComponent },
  data: () => {
    return {
      activeName: "fourth",
      show: false,
      data: [],
      isLoading: false,
      zIndex: 1000,
    };
  },
  mounted() {
    this.app.map.addLayer(layer);
  },
  unmounted() {
    this.app.map.removeLayer(layer);
  },
  methods: {
    open() {
      this.show = true;
      this.zIndex = this.app.widgetZIndex++;
    },
    goBack() {
      this.show = false;
      this.clearData();
    },
    clearData() {
      this.data = [];
      layer.getSource().clear();
    },
    startLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      this.isLoading = false;
    },
    addGroupInfo(group) {
      this.data.push({
        title: group.title,
        images: this.prepareFeaturesImages(group.features),
        properties: this.prepareFeaturesData(group.features),
        geom: this.prepareGeometryData(group.features),
      });
      this.activeName = group.title;
      this.drawGeoms();
    },
    prepareFeaturesImages(features) {
      for (let i = 0; i < features.length; i++) {
        let feature = features[i];
        let properties = feature.properties;
        return properties.fotos;
      }
    },
    prepareGeometryData(features) {
      for (let i = 0; i < features.length; i++) {
        if (features[i].geometry) {
          return features[i].geometry;
        }
      }
    },
    prepareFeaturesData(features) {
      for (let i = 0; i < features.length; i++) {
        let feature = features[i];
        let properties = feature.properties;

        delete properties.fotos;
        delete properties.createdAt;
        delete properties.updatedAt;
        delete properties.deletedAt;

        //data.push(this.renameKeys(properties));
        return this.renameKeys(properties);
      }
      // return data;
    },
    renameKeys(properties) {
      const dict = {
        id_sistema: "ID Sistema",
        id_externo: "ID Externo",
        status: "Status",
        tipo: "Tipo",
        descricao: "Descrição",
        codigo: "Código",
        capacidade: "Capacidade",
        elo: "Elo",
        posicao_chave: "Posição da Chave",
        trecho_primario_id: "Trecho Primário",
        condutor: "Condutor",
        fase: "Fase",
        comprimento: "Comprimento",
        alimentador_id: "Alimentador",
        bitola: "Bitola",
        fases: "Fases",
        trecho_secundario_id: "Trecho Secundário",
        instalacao_carga_id: "Instalação de Carga",
        potencia: "Potência",
        tensao: "Tensão",
        nome: "Nome",
        layer: "Camada",
        id: "ID",
      };

      let newProperties = {};
      for (let key in properties) {
        if (dict[key]) {
          newProperties[dict[key]] = properties[key];
        } else {
          newProperties[key] = properties[key];
        }
      }
      return newProperties;
    },
    drawGeoms() {
      layer.getSource().clear();

      for (let i = 0; i < this.data.length; i++) {
        let geom = this.data[i].geom;
        let title = this.data[i].title;

        if (geom) {
          let olGeom = new GeoJSON().readGeometry(geom, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          });

          let feature = new Feature({
            geometry: olGeom,

          });

          if (title == this.activeName) {
            feature.setStyle(new Style({
              stroke: new Stroke({
                color: '#ff00ff55',
                width: 10,
              }),
              fill: new Fill({
                color: '#ff00ff55',
              }),
              image: new CircleStyle({
                radius: 10,
                fill: new Fill({
                  color: '#ff00ff55',
                }),
                stroke: new Stroke({
                  color: '#ff00ff55',
                }),
              }),
            }));
          }

          layer.getSource().addFeature(feature);
        }
      }
    },
  },
};
</script>

<style scoped>
.container {
  width: calc(600px);
  height: 100%;
  padding: 1.5rem 2rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1001;
  background: #fff;
}

.content {
  width: 100%;
  padding-top: 1.5rem;
  float: right;
}

.title {
  font-size: 1rem;
  font-weight: bold;
}
</style>

<style>
/* .el-tabs--border-card > .el-tabs__content {
  height: calc(100vh - 175px);
} */
.el-descriptions__label.el-descriptions__cell.is-bordered-label {
  font-weight: 700;
  color: #0f0f0f;
  background: #fefefe;
}

.el-descriptions__body .el-descriptions__table.is-bordered .el-descriptions__cell {
  border: none;
  border-bottom: 1px dotted #d8dadf;
  padding: 11px;
}

.el-tabs {
  --el-tabs-header-height: 32px;
}

.el-tabs__item {
  font-size: 12px !important;
  padding: 0 8px !important;
}
</style>
