<template>
    <div class="coordinate">{{ coordinate }}</div>
</template>

<script>
import { transform } from "ol/proj";
import { toStringHDMS } from "ol/coordinate";

export default {
  name: "MouseCoordinate",
  data() {
    return {
      coordinate: "",
    };
  },
  mounted() {
    this.coordinate = toStringHDMS(this.app.config.center, 3);
    this.app.map.on("pointermove", (evt) => {
      const mapProjection = this.app.map.getView().getProjection().getCode();
      const center = transform(evt.coordinate, mapProjection, "EPSG:4326");
      this.coordinate = toStringHDMS(center, 3);
    });
  },
};
</script>

<style scoped>
.coordinate {
  font-size: 11px;
  color: #f0f0f0;
  padding-top: 2px;
}
</style>
