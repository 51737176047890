<template>
  <div class="container" :style="{ zIndex: zIndex }">
    <el-page-header @back="goBack">
      <template #content>
        <span class="title"> EVOLUÇÃO DA FISCALIZAÇÃO </span>
      </template>
    </el-page-header>

    <div v-loading="isLoading" class="content">
      <div ref="piecanvas" class="chart"></div>
      <!-- <el-button class="btn" @click="handleLayers">Ap</el-button> -->
    </div>
  </div>
</template>

<script>
import ApexCharts from 'apexcharts';
import { handleVisibleLayers } from '../helpers/layers.js';

const defaultColors = {
  "Carregado": "#FF204E",
  "Consistido Eletricamente": "#008DDA",
  "Consistido Integridade": "#4CCD99",
  "Consistido por Imagem": "#FFF455",
};

export default {
  name: "InfoComponent",
  components: {},
  data: () => {
    return {
      isLoading: true,
      chart: null,
      labels: [],
      values: [],
      colors: [],
      zIndex: 1000
    };
  },
  mounted() {
    this.getValues();
    this.handleLayers();
    this.zIndex = this.app.widgetZIndex++;
  },
  unmounted() {
    this.chart.destroy();
  },
  methods: {
    handleLayers() {
      let visibleLayers = [];
      const groups = this.app.config.groups;
      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];
        if (group.title === 'Fiscalização' || group.title === 'Trechos de Rede') {
          visibleLayers = visibleLayers.concat(group.layers.map((layer) => layer.id));
        } else if (group.title === 'Pontos Geográficos') {
          for (let j = 0; j < group.layers.length; j++) {
            const layer = group.layers[j];
            if (layer.title.toLowerCase().indexOf('poste') !== -1) {
              visibleLayers.push(layer.id);
            }
          }
        } else if (group.title === 'Outros Pontos') {
          for (let j = 0; j < group.layers.length; j++) {
            const layer = group.layers[j];
            if (layer.title == 'Concentração de Pontos') {
              visibleLayers.push(layer.id);
            }
          }
        } else if (group.title === 'Mapas Base') {
          for (let j = 0; j < group.layers.length; j++) {
            const layer = group.layers[j];
            if (layer.visible === true) {
              visibleLayers.push(layer.id);
            }
          }
        }
      }
      handleVisibleLayers(groups, visibleLayers, this.app.map);
    },
    buildChart() {
      const elm = this.$refs.piecanvas;
      var options = {
        series: this.values,
        chart: {
          type: 'pie',
        },
        labels: this.labels,
        colors: this.colors,
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 600
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      };

      this.chart = new ApexCharts(elm, options);
      this.chart.render();
      this.isLoading = false;
    },
    prepareData(features) {
      this.values = [];
      this.labels = [];
      this.colors = [];

      for (let i = 0; i < features.length; i++) {
        const feature = features[i];
        const properties = feature.properties;
        this.values.push(properties.count);
        this.labels.push(properties.status);
        this.colors.push(defaultColors[properties.status]);
      }
    },
    getValues() {
      const params = new URLSearchParams({
        service: "WFS",
        version: "1.0.0",
        request: "GetFeature",
        typeName: "FiscalizacaoWeb:vw_fiscalizacao",
        outputFormat: "application/json",
        srsName: "EPSG:4326",
      });
      const url = `${this.app.config.geoserver_url}/ows?${params.toString()}`;

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          this.prepareData(data.features);
          this.buildChart();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    goBack() {
      this.show = false;
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.container {
  width: calc(600px);
  height: 100%;
  padding: 1.5rem 2rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  background: #fff;
}

.content {
  width: 100%;
  padding-top: 3rem;
  background: #fff;
  position: relative;
}

.title {
  font-size: 1rem;
  font-weight: bold;
}

.btn {
  margin-top: 1rem;
  margin-bottom: 1rem;
  float: right;
}

.chart {
  width: 100%;
  height: 400px;
}

.text-loading {
  text-align: right;
  font-size: 0.8rem;
  color: #5e5e5e;
}
</style>