<template>
    <div>
        <h3>Galeria de Imagens</h3>
        <el-scrollbar height="600px">
            <el-space spacer=" " wrap>
                <el-image :src="item.url" :preview-src-list="previews" fit="cover" v-for="(item, i) in imgs"
                    :key="item.id" :name="item.id + ''" :initial-index="i" class="image-slot">
                    <template #placeholder>
                        <div class="image-slot">Carregando<span class="dot">...</span></div>
                    </template>
                </el-image>
            </el-space>
        </el-scrollbar>
    </div>
</template>

<script>
export default {
    name: "ImagesComponent",
    props: ["images"],
    data() {
        return {
            imgs: [],
            previews: [],
        };
    },
    mounted() {
        this.updateData();
    },
    methods: {
        updateData() {
            const path = "images/";
            if (this.images instanceof Array) {
                this.imgs = this.images.map((item, i) => {
                    return {
                        id: i,
                        url: path + item,
                    };
                });
            } else {
                try {
                    this.imgs = this.images.split(',').map((item, i) => {
                        return {
                            id: i,
                            url: path + item,
                        };
                    });
                } catch (error) {
                    this.imgs = [];
                }

            }
            this.previews = this.imgs.map((item) => item.url);
        },
    },
    watch: {
        arvores() {
            this.updateData();
        },
    },
};
</script>

<style scoped>
h3 {
    color: #363b42;
    text-transform: uppercase;
    margin-top: 2rem;
    margin-bottom: 1rem;
    letter-spacing: -1px;
}
</style>

<style>
.image-slot {
    width: 150px !important;
    height: 150px !important;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.carousel-img-container {
    width: 100%;
    height: 100%;
    background-size: cover;
}

.carousel-img-blur {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
}

.el-carousel__item h3 {
    color: #475669;
    opacity: 0.75;
    line-height: 580px;
    margin: 0;
    text-align: center;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
</style>